/* You can add global styles to this file, and also import other style files */
@import "~@fontsource/open-sans/400.css";
@import "~@fontsource/open-sans/400-italic.css";
@import "~@fontsource/open-sans/700.css";
@import "~@fontsource/open-sans/700-italic.css";
@import "~@fontsource/roboto-mono";
@import "~@fontsource/carter-one/400.css";
@import "~@fontsource/itim/400.css";
@import "~@fontsource/rowdies/300.css";
@import "~@fontsource/rowdies/700.css";
@import "~@fontsource/gajraj-one/latin-400.css";

body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
}

h1 {
    font-family: 'Carter One', sans-serif;
}

.content {
    margin: auto;
    width: 100%;
    padding: 10px;
    max-width: 1050px;
    box-sizing: border-box;
}

blockquote {
    &::before {
        display: inline;
        content: '“';
    }

    &::after {
        display: inline;
        content: '”';
    }
}

dt {
    font-weight: bold;
}

dd {
    margin: 0;
}